<template>
  <div
    v-if="this.$route.name === 'inzerce-index'"
    class="d-flex align-center"
  >
    <!-- HLADAT -->
    <v-text-field
      v-model="filter_input_search"
      rounded
      dense
      outlined
      clearable
      @click:clear="inputSearch('clear')"
      :prepend-inner-icon="icons.mdiMagnify"
      class="app-bar-search flex-grow-0 ma-2"
      hide-details
      @change="inputSearch"
    ></v-text-field>
    <!-- SCHVALENO -->
    <v-btn
      class="ma-2"
      :color="filter_btn_schvaleno === 'off' ? '#CBC9D2' : filter_btn_schvaleno === 'schvaleno' ? 'success' : 'blue-grey'"
      outlined
      rounded
      dense
      style="line-height:40px;height:40px !important"
      @click="btnFilterSchvaleno"
    >
      <v-icon
        v-if="filter_btn_schvaleno === 'off'"
        left
      >
        {{icons.mdiMinus}}
      </v-icon>
      <v-icon
        v-else-if="filter_btn_schvaleno === 'schvaleno'"
        left
      >
          {{icons.mdiCheck}}
      </v-icon>
      <v-icon
        v-else
        left
      >
          {{icons.mdiClose}}
      </v-icon>
      SCHVÁLENO
    </v-btn>

    <!-- TPP|BRIGADA -->
    <v-btn
      class="ma-2"
      :color="filter_btn_typ_inzeratu === 'off' ? '#CBC9D2' : filter_btn_typ_inzeratu === 'TPP' ? '#F54C3E' : '#FF9D1A'"
      outlined
      rounded
      dense
      style="line-height:40px;height:40px !important"
      @click="btnFilterTypInzeratu"
    >
      <v-icon
        v-if="filter_btn_typ_inzeratu === 'off'"
        left
      >
        {{icons.mdiMinus}}
      </v-icon>
      <v-icon
        v-else-if="filter_btn_typ_inzeratu === 'TPP'"
        left
      >
          {{icons.mdiCheck}}
      </v-icon>
      <v-icon
        v-else
        left
      >
          {{icons.mdiCheck}}
      </v-icon>
      {{ filter_btn_typ_inzeratu === 'BRIGÁDA' ? 'BRI' : 'TPP' }}
    </v-btn>

    <!-- KONZULTANT -->
    <v-autocomplete
      v-model="filter_konzultant_values"
      :items="konzultantOpt"
      outlined
      rounded
      dense
      chips
      clearable
      small-chips
      label="Konzultant"
      multiple
      class="app-bar-search flex-grow-0"
      style="margin:25px 0px 0px 10px;width:240px"
      hide-no-data
      @update:search-input="remoteSearchKonzultant"
      item-text="fullname"
      item-value="val"
      @blur="konzultantSearch"
    ></v-autocomplete>

    <!-- CLEAR FILTER -->
    <v-btn
      class="ma-2"
      outlined
      rounded
      dense
      color="#f54c3e"
      v-show="$store.state.inzerce.filter_is_activate"
      @click="btnClearFilter"
    >
      <v-icon
        left
      >
          {{icons.mdiClose}}
      </v-icon>
      ZRUŠIT
    </v-btn>
  </div>
</template>

<script>
/* eslint-disable-next-line object-curly-newline */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint no-underscore-dangle: "off" */
/* eslint no-unused-vars: "off" */
/* eslint prefer-destructuring: ["off", {VariableDeclarator: {object: true}}] */
/* eslint no-plusplus: "off" */
/* eslint prefer-const: "off" */
/* eslint no-param-reassign: "off" */
/* eslint padded-blocks: "off" */
/* eslint object-curly-newline: "off" */
/* eslint no-else-return: "off" */

import axios from 'axios'
import { mdiMagnify, mdiBellOutline, mdiGithub, mdiWeb, mdiFacebook, mdiAlphaABoxOutline, mdiCheckOutline, mdiCheck, mdiMinus, mdiClose } from '@mdi/js'

export default {
  data() {
    return {
      filter_input_search: '',
      filter_btn_schvaleno: 'off',
      filter_btn_typ_inzeratu: 'off',
      filter_konzultant_values: [],

      konzultantOpt: [],

      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
        mdiWeb,
        mdiFacebook,
        mdiAlphaABoxOutline,
        mdiCheckOutline,
        mdiCheck,
        mdiMinus,
        mdiClose,
      },
    }
  },
  created() {

  },
  methods: {
    inputSearch(p_action) {

      // console.log('[index-filter.vue], inputSearch, p_action =>', p_action, this.filter_input_search)
      if (p_action === 'clear') {
        this.filter_input_search = ''
        this.$store.state.inzerce.filter_search = ''
      } else {
        this.$store.state.inzerce.filter_search = this.filter_input_search
      }

      // console.log('[index-filter.vue], inputSearch, this.$store.state.inzerce.filter_search', this.$store.state.inzerce.filter_search)
    },
    btnFilterSchvaleno() {
      if (this.filter_btn_schvaleno === 'off') {
        this.filter_btn_schvaleno = 'schvaleno'
      } else if (this.filter_btn_schvaleno === 'schvaleno') {
        this.filter_btn_schvaleno = 'neschvaleno'
      } else {
        this.filter_btn_schvaleno = 'off'
      }
      this.$store.state.inzerce.filter_schvaleno = this.filter_btn_schvaleno
    },
    btnFilterTypInzeratu() {
      if (this.filter_btn_typ_inzeratu === 'off') {
        this.filter_btn_typ_inzeratu = 'TPP'
      } else if (this.filter_btn_typ_inzeratu === 'TPP') {
        this.filter_btn_typ_inzeratu = 'BRIGÁDA'
      } else {
        this.filter_btn_typ_inzeratu = 'off'
      }

      this.$store.state.inzerce.filter_typ_inzeratu = this.filter_btn_typ_inzeratu
    },
    remoteSearchKonzultant(p_val) {
      if (!p_val) {
        return
      }

      // console.log('[index-filter.vue], remoteSearchKonzultant, p_val =>', p_val)

      const config = {
        url: `${process.env.VUE_APP_BASE_API}/api/v2/inzerce/konzultant/search/${p_val}`,
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'get',
      }
      axios(config)
        .then(resp => {
          this.konzultantOpt = []
          for (let index = 0; index < resp.data.db_data.length; index++) {
            const element = resp.data.db_data[index]
            this.konzultantOpt.push({ val: element.id, fullname: `${element.firstname}  ${element.surname}` })
          }
        })
        .catch(err => {
          console.error(err)
        })
    },
    konzultantSearch() {
      this.$store.state.inzerce.filter_konzultant_search = this.filter_konzultant_values
    },
    btnClearFilter() {
      this.filter_input_search = ''
      this.$store.state.inzerce.filter_search = ''
      this.filter_btn_schvaleno = 'off'
      this.$store.state.inzerce.filter_schvaleno = this.filter_btn_schvaleno
      this.filter_btn_typ_inzeratu = 'off'
      this.$store.state.inzerce.filter_typ_inzeratu = this.filter_btn_typ_inzeratu
      this.filter_konzultant_values = []
      this.$store.state.inzerce.filter_konzultant_search = this.filter_konzultant_values
      this.$store.state.inzerce.filter_clear = true
    },
  },
}
</script>
