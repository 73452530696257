<template>
  <v-app>
    <vertical-nav-menu v-show="is_logged_in" :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar
      app
      flat
      absolute
      color="transparent"
      v-show="is_logged_in"
    >
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <!-- Left Content -->
          <!-- hamburger icon -->
          <v-app-bar-nav-icon
            class="d-block d-lg-none me-2"
            @click="isDrawerOpen = !isDrawerOpen"
          ></v-app-bar-nav-icon>
          <!-- filter input (zobrazi sa vsade, okrem inzerce) -->
          <global-filter
            v-if="$route.name !== 'inzerce-index'"
          >
          </global-filter>

          <!-- filter pre inzerci (zobrazi sa len ked je uzivatel prekliknuty v inzerci) -->
          <inzerce-filter></inzerce-filter>

          <v-spacer></v-spacer>

          <!-- Right Content -->
          <!-- ODKAZ: indexnoslus.cz -->
          <a
            href="https://www.indexnoslus.cz"
            target="_blank"
            rel="nofollow"
          >
            <v-icon class="ms-6 me-4">
              {{ icons.mdiWeb }}
            </v-icon>
          </a>
          <!-- ODKAZ: facebook -> indexnoslus.cz -->
          <a
            href="https://www.facebook.com/indexnosluscr"
            target="_blank"
            rel="nofollow"
          >
            <v-icon class="me-3">
              {{ icons.mdiFacebook }}
            </v-icon>
          </a>
          <!-- ODKAZ: lipno.indexnoslus.cz -->
          <a
            href="https://lipno.indexnoslus.cz/"
            target="_blank"
            rel="nofollow"
          >
            <v-icon class="me-3">
              {{ icons.mdiAlphaABoxOutline }}
            </v-icon>
          </a>
          <theme-switcher></theme-switcher>
          <v-btn
            icon
            small
            class="ms-3"
          >
            <v-icon>
              {{ icons.mdiBellOutline }}
            </v-icon>
          </v-btn>

          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container pa-6">
        <slot></slot>
      </div>
    </v-main>

    <v-footer
      app
      inset
      color="transparent"
      absolute
      height="56"
      class="px-0"
    >
      <div class="boxed-container w-full">
        <div class="mx-6 d-flex justify-space-between">
          <span>
            <a
              href="https://indexnoslus.cz"
              class="text-decoration-none"
              target="_blank"
            >INDEX NOSLUŠ s.r.o.</a></span>
          <span class="d-sm-inline d-none">
            <a
              href="https://irool.cz"
              target="_blank"
              class="me-6 text--secondary text-decoration-none"
            >Copyright © 2017-{{ new Date() | moment("YYYY") }} RooL (www.irool.cz)</a>
            <!-- <a
              href="https://themeselection.com/blog/"
              target="_blank"
              class="me-6 text--secondary text-decoration-none"
            >Blog</a>
            <a
              href="https://github.com/themeselection/materio-vuetify-vuejs-admin-template-free/blob/main/LICENSE"
              target="_blank"
              class="text--secondary text-decoration-none"
            >MIT Licence</a> -->
          </span>
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
/* eslint object-curly-newline: ["off", "off"] */
/* eslint-env es6 */

import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiBellOutline, mdiGithub, mdiWeb, mdiFacebook, mdiAlphaABoxOutline, mdiCheckOutline, mdiCheck, mdiMinus } from '@mdi/js'
import InzerceFilter from '@/views/inzerce/components/index-filter.vue'
import GlobalFilter from '@/components/global-filter/index.vue'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import store from '../store'

export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
    InzerceFilter,
    GlobalFilter,
  },
  setup() {
    const isDrawerOpen = ref(null)

    return {
      isDrawerOpen,
      is_logged_in: false,

      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
        mdiWeb,
        mdiFacebook,
        mdiAlphaABoxOutline,
        mdiCheckOutline,
        mdiCheck,
        mdiMinus,
      },

    }
  },
  watch: {

  },
  created() {
    this.is_logged_in = store.getters.isAuthenticated
  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

</style>
