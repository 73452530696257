/* eslint no-underscore-dangle: "off" */

import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import 'tiptap-vuetify/dist/main.css'
import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import jwt_decode from 'jwt-decode'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'

// import * as Sentry from '@sentry/vue'
// import { BrowserTracing } from '@sentry/tracing'
import { VBtn } from 'vuetify/lib'
import store from './store'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import { USER_REQUEST } from './store/actions/user'

/* eslint-disable import/prefer-default-export */
export const eventBus = new Vue()

// if (process.env.VUE_APP_SENTRY_DSN && process.env.VUE_APP_SENTRY_DSN.length > 0) {
//   Sentry.init({
//     Vue,
//     dsn: process.env.VUE_APP_SENTRY_DSN, // "https://examplePublicKey@o0.ingest.sentry.io/0",
//     integrations: [
//       new BrowserTracing({
//         routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//         tracingOrigins: ['localhost', 'velin.noslus.cz', 'dev.velin', /^\//],
//       }),
//     ],

//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
//   })
// }

Vue.use(VueLodash, { name: 'lh', lodash })

Vue.config.productionTip = false

const token = localStorage.getItem('user-token')
if (token) {
  axios.defaults.headers.common['x-access-token'] = token
  const decoded = jwt_decode(token)
  store.dispatch(USER_REQUEST, decoded._id).then(() => {

  })
}

Vue.use(VueAxios, axios)

Vue.use(require('vue-moment'))

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdiSvg',
})

Vue.component('v-btn', VBtn)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
