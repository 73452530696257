/* eslint-disable */
import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
} from '../actions/auth'
import { USER_REQUEST } from '../actions/user'
import axios from 'axios'

const state = {
  token: localStorage.getItem('user-token') || '',
  status: '',
  hasLoadedOnce: false,
}

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
}

const actions = {
  [AUTH_REQUEST]: ({ commit, dispatch }, credentials) => {
    return new Promise(async (resolve, reject) => {
      commit(AUTH_REQUEST)
      if (credentials.google_login === true) {
        const resp = credentials.resp
        localStorage.setItem('user-token', resp.data.db_data.token.value)
        if (resp.data.code === 10) {
          axios.defaults.headers.common['x-access-token'] = resp.data.db_data.token.value
        }
        commit(AUTH_SUCCESS, resp.data.db_data.token.value)
        dispatch(USER_REQUEST, resp.data.db_data.user._id)
        resolve(resp.data.db_data.token)
      } else {
        axios.post(process.env.VUE_APP_BASE_API + '/api/v2/user/authenticate', credentials)
          .then(resp => {
            // console.log('[auth.js] *** axios response', resp)
            // console.log('[auth.js] *** axios response, credentials => ', credentials)
            localStorage.setItem('user-token', resp.data.db_data.token.value)
            if (resp.data.db_data && resp.data.db_data.token_permit) {
              localStorage.setItem('user-token_permit', resp.data.db_data.token_permit.value)
            }
            // Here set the header of your ajax library to the token value.
            // vloz do kazdeho requestu vytvoreneho axiom
            if (resp.data.code === 10) {
              axios.defaults.headers.common['x-access-token'] = resp.data.db_data.token.value
            }
            commit(AUTH_SUCCESS, resp.data.db_data.token.value)
            dispatch(USER_REQUEST, resp.data.db_data.user._id)
            resolve(resp.data.db_data.token)
          })
          .catch(err => {
            commit(AUTH_ERROR, err)
            localStorage.removeItem('user-token')
            localStorage.removeItem('user-token_permit')
            reject(err)
          })
      }
    })
  },
  [AUTH_LOGOUT]: ({ commit }, credentials) => {
    return new Promise(resolve => {
      axios.post(process.env.VUE_APP_BASE_API + '/api/v2/user/logout', credentials)
        .then(resp => {
          if (resp.data.code === 900) {
            localStorage.removeItem('user-token')
            localStorage.removeItem('user-token_permit')

            commit(AUTH_LOGOUT)
          }
          resolve()
      })
    })
  },
}

const mutations = {
  [AUTH_REQUEST]: state => {
    state.status = 'loading'
  },
  [AUTH_SUCCESS]: (state, resp) => {
    state.status = 'success'
    state.token = resp
    state.hasLoadedOnce = true
  },
  [AUTH_ERROR]: state => {
    state.status = 'error'
    state.hasLoadedOnce = true
  },
  [AUTH_LOGOUT]: state => {
    state.token = ''
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
