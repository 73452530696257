import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import auth from './modules/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    global: { // inicizalizuj $store.state.global
      filter_search: '',
    },
    inzerce: { // inicizalizuj $store.state.inzerce
      filter_is_activate: false,
      filter_schvaleno: 'off',
      filter_typ_inzeratu: [],
      filter_search: '',
      filter_konzultant_search: [],
      filter_clear: false,
    },
  },
  mutations: {},
  actions: {},
  modules: {
    user,
    auth,
  },
})
