import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

// console.log('[router] *** store', store)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()

    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  // console.log('===>', store.getters)
  if (store.getters.isAuthenticated === true) {
    next()

    return
  }
  next('/login')
}

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    beforeEnter: ifAuthenticated,
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  {
    path: '/typography',
    name: 'typography',
    component: () => import('@/views/typography/Typography.vue'),
  },
  {
    path: '/icons',
    name: 'icons',
    component: () => import('@/views/icons/Icons.vue'),
  },
  {
    path: '/cards',
    name: 'cards',
    component: () => import('@/views/cards/Card.vue'),
  },
  {
    path: '/simple-table',
    name: 'simple-table',
    component: () => import('@/views/simple-table/SimpleTable.vue'),
  },
  {
    path: '/form-sos',
    name: 'form-sos',
    component: () => import('@/views/pages/form-sos/FormSOS.vue'),
  },
  {
    path: '/pages/clanky',
    name: 'pages-clanky',
    beforeEnter: ifAuthenticated,
    component: () => import('@/views/pages/clanky/Clanky.vue'),
  },
  {
    path: '/pages/potrebuji/cze',
    name: 'pages-potrebuji-cze',
    beforeEnter: ifAuthenticated,
    component: () => import('@/views/pages/potrebuji/Potrebuji.vue'),
  },
  {
    path: '/pages/potrebuji/svk',
    name: 'pages-potrebuji-svk',
    beforeEnter: ifAuthenticated,
    component: () => import('@/views/pages/potrebuji/Potrebuji.vue'),
  },
  {
    path: '/pages/tel-seznam',
    name: 'pages-tel-seznam',
    beforeEnter: ifAuthenticated,
    component: () => import('@/views/pages/tel-seznam/TelSeznam.vue'),
  },
  {
    path: '/pages/videonavody',
    name: 'pages-videonavody',
    beforeEnter: ifAuthenticated,
    component: () => import('@/views/pages/videonavody/Videonavody.vue'),
  },
  {
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    beforeEnter: ifAuthenticated,
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
  },
  {
    path: '/inzerce/index',
    name: 'inzerce-index',
    beforeEnter: ifAuthenticated,
    component: () => import('@/views/inzerce/index.vue'),
  }, {
    path: '/supersaas/index',
    name: 'pages-supersaas-index',
    beforeEnter: ifAuthenticated,
    component: () => import('@/views/pages/supersaas/SuperSaas.vue'),
  },
  {
    path: '/openai/index',
    name: 'pages-openai-index',
    beforeEnter: ifAuthenticated,
    component: () => import('@/views/pages/openai/OpenAI.vue'),
  },
  {
    path: '/newsletter/index',
    name: 'pages-newsletter-index',
    beforeEnter: ifAuthenticated,
    component: () => import('@/views/pages/newsletter/Newsletter.vue'),
  },
  {
    path: '/newsletter/unsubscribe/:email_address',
    name: 'pages-newsletter-unsubscribe',
    component: () => import('@/views/pages/newsletter/NewsletterUnsubscribe.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/login',
    name: 'pages-login',
    component: () => import('@/views/pages/Login.vue'),
    beforeEnter: ifNotAuthenticated,
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/velin',
    name: 'pages-login',
    component: () => import('@/views/pages/Login.vue'),
    beforeEnter: ifNotAuthenticated,
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/pages/register',
    name: 'pages-register',
    component: () => import('@/views/pages/Register.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/external-hireme',
    name: 'external-hireme',
    beforeEnter() {
      window.open('https://hm.indexnoslus.cz', '_blank')
    },
  },
  {
    path: '/external-karat',
    name: 'external-karat',
    beforeEnter() {
      // console.log('profil =>', store.getters.getProfile.login)
      window.open(`http://127.0.0.1:19900/tool/karat/${store.getters.getProfile.login}`, '_blank')
    },
  },
  {
    path: '/external-gdrive',
    name: 'external-gdrive',
    beforeEnter() {
      window.open('https://drive.google.com/drive/my-drive', '_blank')
    },
  },
  {
    path: '/external-podpis',
    name: 'external-podpis',
    beforeEnter() {
      window.open('https://drive.noslus.cz/d/s/qdVCeQfu2j50ZrapjldxzFDJkwsrhRM8/K8uYDxRv1NPWYRcWZY8ZC63_IFxnRjAR-Ub5gk0uP4wk', '_blank')
    },
  },

  // {
  //   path: '/external-nas01',
  //   name: 'external-nas01',
  //   beforeEnter() {
  //     window.open('https://nas01.noslus.cz', '_blank')
  //   },
  // },
  // {
  //   path: '/external-nas02',
  //   name: 'external-nas02',
  //   beforeEnter() {
  //     window.open('https://in-nas02.noslus.cz', '_blank')
  //   },
  // },
  // {
  //   path: '/external-gitlab',
  //   name: 'external-gitlab',
  //   beforeEnter() {
  //     window.open('https://gitlab.irool.cz', '_blank')
  //   },
  // },
  {
    path: '/external-ron',
    name: 'external-ron',
    beforeEnter() {
      window.open('https://index.outsourcing.softip.sk/ronportal/index.php', '_blank')
    },
  },
  {
    path: '/external-clanky-udrzba',
    name: 'external-clanky-udrzba',
    beforeEnter() {
      window.open(`${process.env.VUE_APP_BASE_API}/api/v2/tool/clanky_udrzba`)
    },
  },
  {
    path: '/external-telseznam-udrzba',
    name: 'external-telseznam-udrzba',
    beforeEnter() {
      window.open(`${process.env.VUE_APP_BASE_API}/api/v2/tool/telseznam_udrzba`, '_blank')
    },
  },
  {
    path: '/external-videonavody-udrzba',
    name: 'external-videonavody-udrzba',
    beforeEnter() {
      window.open(`${process.env.VUE_APP_BASE_API}/api/v2/tool/videonavody_udrzba`, '_blank')
    },
  },
  {
    path: '/pages/users',
    name: 'pages-users',
    beforeEnter: ifAuthenticated,
    component: () => import('@/views/pages/users/Users.vue'),
  },
  {
    path: '/pages/terminals',
    name: 'pages-terminals',
    beforeEnter: ifAuthenticated,
    component: () => import('@/views/pages/terminals/Terminals.vue'),
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
