<template>
  <div>
    <v-text-field
      v-model="filter_input_search"
      rounded
      dense
      outlined
      clearable
      :prepend-inner-icon="icons.mdiMagnify"
      class="app-bar-search flex-grow-0"
      hide-details
      @click:clear="inputSearch('clear')"
      @change="inputSearch"
    ></v-text-field>
  </div>
</template>

<script>
/* eslint-disable-next-line object-curly-newline */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint no-underscore-dangle: "off" */
/* eslint no-unused-vars: "off" */
/* eslint prefer-destructuring: ["off", {VariableDeclarator: {object: true}}] */
/* eslint no-plusplus: "off" */
/* eslint prefer-const: "off" */
/* eslint no-param-reassign: "off" */
/* eslint padded-blocks: "off" */
/* eslint object-curly-newline: "off" */
/* eslint no-else-return: "off" */

import {
  mdiMagnify, mdiBellOutline, mdiGithub, mdiWeb, mdiFacebook, mdiAlphaABoxOutline, mdiCheckOutline, mdiCheck, mdiMinus,
} from '@mdi/js'
import { eventBus } from '../../main'

export default {
  setup() {
    return {
      filter_input_search: '',

      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
        mdiWeb,
        mdiFacebook,
        mdiAlphaABoxOutline,
        mdiCheckOutline,
        mdiCheck,
        mdiMinus,
      },
    }
  },
  beforeDestroy() {
    // to destroy the event
    eventBus.$off('set-FilterSearch')
  },
  created() {
    // to listen the event
    eventBus.$on('set-FilterSearch', this.setFilterSearch)
  },
  methods: {
    inputSearch(p_action) { // mozne hodnoty => 'clear' - vola sa ked sa klikne na tlacitko zmaz text v inpute
      if (p_action === 'clear') {
        this.filter_input_search = ''
        this.$store.state.global.filter_search = ''
      } else {
        this.$store.state.global.filter_search = this.filter_input_search
      }
    },
    setFilterSearch(p_text) {
      this.filter_input_search = p_text
      this.inputSearch()
    },
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}
</style>
