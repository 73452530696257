<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <v-img
          :src="require('@/assets/images/logos/V.png')"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
        <v-slide-x-transition>
          <h2 class="app-title text--primary">
            VELÍN
          </h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list
      expand
      shaped
      class="vertical-nav-menu-items pr-5"
    >
      <nav-menu-link
        title="Velín"
        :to="{ name: 'dashboard' }"
        :icon="icons.mdiViewDashboard"
      ></nav-menu-link>
      <nav-menu-link
        title="Články"
        :to="{ name: 'pages-clanky'}"
        :icon="icons.mdiBookOpenBlankVariant"
      ></nav-menu-link>
      <nav-menu-link
        title="Tel. seznam"
        :to="{ name: 'pages-tel-seznam'}"
        :icon="icons.mdiContacts"
      ></nav-menu-link>
      <nav-menu-link
        title="Potřebuji"
        :to="{ name: 'pages-potrebuji-cze'}"
        :icon="icons.mdiClipboardList"
      >
      </nav-menu-link>
      <nav-menu-link
        title="Video návody"
        :to="{ name: 'pages-videonavody'}"
        :icon="icons.mdiFileVideo"
      ></nav-menu-link>
      <!-- <nav-menu-link
        title="Account Settings"
        :to="{ name: 'pages-account-settings'}"
        :icon="icons.mdiAccountCogOutline"
      ></nav-menu-link> -->
      <nav-menu-link
        title="SuperSaas"
        :to="{ name: 'pages-supersaas-index'}"
        :icon="icons.mdiCalendarSearch"
      ></nav-menu-link>
      <nav-menu-link
        title="Slovensko"
        :to="{ name: 'pages-potrebuji-svk'}"
        :icon="icons.mdiClipboardListOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="chat GPT"
        :to="{ name: 'pages-openai-index'}"
        :icon="icons.mdiRobotHappyOutline"
      ></nav-menu-link>
      <nav-menu-link
        v-if="$store.getters.getProfile.is_admin || newsletter_users.indexOf(transformLogin($store.getters.getProfile.login)) > -1"
        title="Newsletter"
        :to="{ name: 'pages-newsletter-index'}"
        :icon="icons.mdiNewspaper"
      ></nav-menu-link>
      <nav-menu-group
        title="Inzerce"
        :icon="icons.mdiCardBulletedSettings"
      >
        <nav-menu-link
          title="Přehled"
          :to="{ name: 'inzerce-index' }"
          :icon="icons.mdiAccountHardHat"
        ></nav-menu-link>
        <!-- <nav-menu-link
          title="Statistiky"
          :to="{ name: 'pages-register' }"
          target="_blank"
        ></nav-menu-link>
        <nav-menu-link
          title="Error"
          :to="{ name: 'error-404' }"
          target="_blank"
        ></nav-menu-link> -->
      </nav-menu-group>
      <nav-menu-group
        v-if="$store.getters.getProfile.is_admin"
        title="Nástroje"
        :icon="icons.mdiHammerScrewdriver"
      >
        <nav-menu-link
          title="články - údržba"
          :to="{ name: 'external-clanky-udrzba' }"
          :icon="icons.mdiBookSync"
        ></nav-menu-link>
        <nav-menu-link
          title="tel. seznam - údržba"
          :to="{ name: 'external-telseznam-udrzba' }"
          :icon="icons.mdiBookSync"
        ></nav-menu-link>
        <nav-menu-link
          title="videonávody - údržba"
          :to="{ name: 'external-videonavody-udrzba' }"
          :icon="icons.mdiBookSync"
        ></nav-menu-link>
        <nav-menu-link
          title="správa uživatelů"
          :to="{ name: 'pages-users' }"
          :icon="icons.mdiBookSync"
        ></nav-menu-link>
        <nav-menu-link
          title="správa terminálů"
          :to="{ name: 'pages-terminals' }"
          :icon="icons.mdiBookSync"
        ></nav-menu-link>
      </nav-menu-group>
      <nav-menu-section-title title="APLIKACE"></nav-menu-section-title>
      <nav-menu-link
        title="Hire Me"
        :to="{ name: 'external-hireme' }"
        :icon="icons.mdiAlphaHCircle"
      ></nav-menu-link>
      <nav-menu-link
        title="IS Karat"
        :to="{ name: 'external-karat' }"
        :icon="icons.mdiAlphaKBox"
      ></nav-menu-link>
      <nav-menu-link
        title="Docházka"
        :to="{ name: 'external-ron' }"
        :icon="icons.mdiAccountArrowRightOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Google disk"
        :to="{ name: 'external-gdrive' }"
        :icon="icons.mdiGoogleDrive"
      ></nav-menu-link>
      <nav-menu-link
        title="Generátor podpisu"
        :to="{ name: 'external-podpis' }"
        :icon="icons.mdiDraw"
      ></nav-menu-link>
      <!-- <nav-menu-link
        title="IN-NAS01"
        :to="{ name: 'external-nas01' }"
        :icon="icons.mdiNas"
      ></nav-menu-link>
      <nav-menu-link
        title="IN-NAS02"
        :to="{ name: 'external-nas02' }"
        :icon="icons.mdiNas"
      ></nav-menu-link>
      <nav-menu-link
        title="Git Lab"
        :to="{ name: 'external-gitlab' }"
        :icon="icons.mdiGitlab"
      ></nav-menu-link> -->
      <!-- <nav-menu-link
        title="Typography"
        :to="{ name: 'typography' }"
        :icon="icons.mdiAlphaTBoxOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Icons"
        :to="{ name: 'icons' }"
        :icon="icons.mdiEyeOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Cards"
        :to="{ name: 'cards' }"
        :icon="icons.mdiCreditCardOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Tables"
        :to="{ name: 'simple-table' }"
        :icon="icons.mdiTable"
      ></nav-menu-link>
      <nav-menu-link
        title="Form Layouts"
        :to="{ name: 'form-layouts' }"
        :icon="icons.mdiFormSelect"
      ></nav-menu-link> -->
    </v-list>
    <!-- <a
      href="https://themeselection.com/products/materio-vuetify-vuejs-admin-template"
      target="_blank"
      rel="nofollow"
    >
      <v-img
        :src="require(`@/assets/images/pro/upgrade-banner-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
        alt="upgrade-banner"
        transition="scale-transition"
        class="upgrade-banner mx-auto"
        max-width="230"
      ></v-img>
    </a> -->
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
  mdiAccountDetailsOutline,
  mdiFileVideo,
  mdiBookVariant,
  mdiBookOpenBlankVariant,
  mdiContacts,
  mdiViewDashboard,
  mdiCardBulletedSettings,
  mdiAlphaHCircle,
  mdiNas,
  mdiGitlab,
  mdiAlphaKBox,
  mdiAccountHardHat,
  mdiAccountArrowRightOutline,
  mdiHammerScrewdriver,
  mdiBookAccount,
  mdiBookSync,
  mdiClipboardList,
  mdiClipboardListOutline,
  mdiCalendarSearch,
  mdiRobotHappyOutline,
  mdiNewspaper,
  mdiDraw,
  mdiGoogleDrive,
} from '@mdi/js'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  setup() {
    return {
      icons: {
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
        mdiAccountDetailsOutline,
        mdiFileVideo,
        mdiBookVariant,
        mdiBookOpenBlankVariant,
        mdiContacts,
        mdiViewDashboard,
        mdiCardBulletedSettings,
        mdiAlphaHCircle,
        mdiNas,
        mdiGitlab,
        mdiAlphaKBox,
        mdiAccountHardHat,
        mdiAccountArrowRightOutline,
        mdiHammerScrewdriver,
        mdiBookAccount,
        mdiBookSync,
        mdiClipboardList,
        mdiClipboardListOutline,
        mdiCalendarSearch,
        mdiRobotHappyOutline,
        mdiNewspaper,
        mdiDraw,
        mdiGoogleDrive,
      },

      newsletter_users: ['marketingsk', 'slovensko', 'hodekj', 'kutkovad', 'mokranovad', 'kocsisovad', 'krupickap', 'm.kissimon', 'd.tazky@indexnoslus.sk', 'm.kramarcik@indexnoslus.sk', 'z.danislavova@indexnoslus.sk'],
    }
  },
  methods: {
    transformLogin(login) {
      return login ? login.replace('NOSLUS\\', '') : login
    },
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }

  /* Prispôsobenie vonkajšieho kontajnera scrollbaru */
  ::-webkit-scrollbar {
    width: 1px; /* Šírka scrollbaru */
  }
}
</style>
